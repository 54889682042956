<template>
  <v-card flat>
    <template>
      <v-layout class="my-auto">
        <barcode-scanner v-if="payload.showScanCode" v-model="search" :search="search" :scannerPayload="scannerPayload" :loading="scannerFieldLoading"></barcode-scanner>
        <v-text-field v-else solo :label="$t('message.common.search')" class="pa-0" v-model="search" hide-details dense></v-text-field>
        <!-- <v-text-field solo :label="$t('message.common.search')" class="pa-0" v-model="search" hide-details dense></v-text-field> -->
      </v-layout>
      <v-card-title class="pa-0 pl-1 mt-1">
        <v-btn color="primary" v-if="payload.addBtnTo && checkActionPermission(payload.module, CONSTANTS.CREATE)" fab x-small id="newbtnTo" :to="payload.addBtnTo" class="ml-3">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <h4>{{ payload.isCustomModules ? payload.moduleTitle : $t(payload.moduleTitle) }}</h4>
        <v-spacer></v-spacer>
        <!-- <h6>{{payload.list.length}} from {{payload.totalCount}}</h6> -->
        <h6 class="mr-4">{{payload.list.length}} {{ $t('message.common.of') }} {{payload.totalCount}}</h6>
      </v-card-title>
    </template>
    <v-card-text class="pa-0 mt-2 mb-4">
      <v-card outlined color="#e8eef0" class="pa-0 mt-0" v-if="payload.itemHeaders && payload.list && payload.list.length">
        <v-row class="ma-0">
          <template v-for="(header, index) in payload.itemHeaders">
            <v-col :cols="payload.items[index].cols" :class="payload.items[index].class" :key="index">
              <strong>{{$t(header)}}</strong>
            </v-col>
          </template>
        </v-row>
      </v-card>
      <v-virtual-scroll
        v-show="payload.list.length"
        :items="payload.list"
        :item-height="$vuetify.breakpoint.xs ? id == 'deviationScrollList' || id == 'customModuleScrollList'? '150' : '110' : payload.width"
        max-height="65vh"
        :id="id"
      >
        <template v-slot:default="{ item }">
          <v-card :min-height="$vuetify.breakpoint.xs ? '100' : '55'" :max-height="$vuetify.breakpoint.xs ? '150' : '110'" style="overflow:hidden" outlined color="#e8eaf6" class="pa-1 mt-1" @click="checkActionPermission(payload.module, CONSTANTS.EDIT) ? payload.editHandler(item) : ''">
            <v-card-text class="pa-1">
              <v-row>
                <template>
                  <template v-for="(colValues, index) in payload.items">
                    <template v-if="colValues.value">
                      <v-col :cols="colValues.cols" :class="colValues.class" :key="index">
                        <div v-if="colValues.isParagraph" class="custom-height"  :class="item[colValues.value] && colValues.value == 'description' && item[colValues.value].length > 100 ? 'd-inline-block text-truncate': ''" style="max-width:150px; white-space:normal;">
                          {{item[colValues.value]}}
                        </div>
                        <span v-else :class="colValues.spanClass"><strong v-if="colValues.label">{{colValues.label}}: </strong> <br v-if="colValues.label" />{{ item[colValues.value] }}</span>
                      </v-col>
                    </template>
                    <template v-else>
                      <v-col :cols="colValues.cols" :class="colValues.class" :key="index">
                        <v-row :class="colValues.rowClass">
                          <template v-for="(subitems, i) in colValues.subItems">
                            <v-col :cols="subitems.cols" :class="subitems.class" :key="i">
                              <template v-if="subitems.value === 'is_completed'">
                               <!--  <span :class="subitems.spanClass"><strong>{{ $t('message.projects.status') }}:</strong> </span><br />
                                <span :class="subitems.spanClass">{{item[subitems.value] === 1 ? $t('message.customModule.completed') : $t('message.customModule.notCompleted') }}</span>
                              </template>
                               <template v-else-if="subitems.isTranslatable"> -->
                                <v-select class="px-0 pr-2" v-if="payload.moduleTitle === 'message.layout.drawing'" dense hide-details @click.stop="" @change="saveStatusUpdate(payload.list.indexOf(item))" v-model="item[subitems.value]" item-text="text" item-value="value" :items="subitems.items" label="Status" outlined ></v-select>
                                <template v-else>
                                  <span :class="subitems.spanClass" v-if="!payload.itemHeaders"><strong>{{ $t('message.projects.status') }}:</strong> </span><br v-if="!payload.itemHeaders" />
                                  <span :class="subitems.spanClass">{{item[subitems.value] === 1 ? $t('message.customModule.completed') : $t('message.customModule.notCompleted') }}</span>
                                </template>
                                </template>
                                <template v-else-if="subitems.isTranslatable">
                                <span :class="subitems.spanClass">{{ $t(item[subitems.value]) }}</span>
                              </template>
                              <span v-else :class="subitems.spanClass">{{$t(item[subitems.value])}}</span>
                            </v-col>
                          </template>
                        </v-row>
                      </v-col>
                    </template>
                  </template>
                </template>
                <v-col v-if="payload.actionSize" :cols="payload.actionSize" class="pa-0 pr-1 mt-3">
                  <v-layout row wrap justify-end class="mx-0">
                    <v-btn max-height="24" max-width="24" icon v-if="!payload.isChecklist && payload.hasDelete && checkActionPermission(payload.module, CONSTANTS.DELETE)" @click.stop="deleteRecord(item.id)" color="error">
                      <v-icon>mdi-delete-circle</v-icon>
                    </v-btn>
                  </v-layout>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
      </v-virtual-scroll>
      <p class="text-center px-5 py-0">
        <v-progress-linear
          color="deep-purple accent-4"
          indeterminate
          rounded
          v-if="payload.loading"
          height="6"
        ></v-progress-linear>
        <v-progress-circular
          indeterminate
          color="primary"
          class="mx-auto"
          v-if="false"
        ></v-progress-circular>
      </p>
      <v-alert prominent text v-if="!payload.loading && (payload.list.length === 0)" type="info" class="mt-2" >
        <v-row align="center">
          <v-col class="grow">{{ $t('message.common.noResults') }}</v-col>
        </v-row>
      </v-alert>
    </v-card-text>
  </v-card>
</template>
<script>
import PermissionsMixin from '@/mixins/permissions'
import { mapGetters } from 'vuex'
import { hostAppApi } from '@/plugins/axios_settings'
export default {
  mixins: [PermissionsMixin],
  props: ['payload', 'id'],
  data () {
    return {
      search: '',
      debouncedSearch: null,
      bottom: false,
      scannerPayload: {
        placeholder: 'message.mixed.scanSerialNumber',
        change: this.getRespectiveEquipment,
        attrRef: 'scannerFieldFocus',
        attrId: 'scannerField'
      },
      scannerFieldLoading: false
    }
  },
  components: {
    'barcode-scanner': () => import('@/components/BarQrCodeScanner')
  },
  computed: {
    ...mapGetters(['getHostRefApi', 'userId']),
    paginationInfo () {
      return this.recordsInfo
    }
  },
  mounted () {
    // search after some ms
    this.debouncedSearch = this.$formatter.debounce(() => {
      this.$eventBus.$emit('loadList', this.search)
    }, 750)
    // check if id loaded then do the below
    const el = document.getElementById(this.id)
    if (el && !this.payload.listenerAdded) {
      el.addEventListener('scroll', () => {
        this.bottom = this.bottomVisible()
      })
      this.payload.listenerAdded = true
    }
  },
  methods: {
    saveStatusUpdate (index) {
      const moduleId = this.payload.list[index].id
      const header = { 'Content-Type': 'multipart/form-data' }
      const formData = new FormData()
      const url = `${this.getHostRefApi}project_drawing/${moduleId}/drawing_status`
      formData.append('is_completed', this.payload.list[index].is_completed)
      formData.append('id', moduleId)
      formData.append('project_id', this.$route.params.project_id)
      hostAppApi.post(url, formData, { headers: header })
        .then((response) => {
          this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: response.data.message })
        })
    },
    deleteRecord (id) {
      this.$eventBus.$emit('deleteItems', { module: this.payload.module, id })
    },
    bottomVisible () {
      const el = document.getElementById(this.id)
      const scrollY = el.scrollTop
      const visible = el.clientHeight
      const pageHeight = el.scrollHeight
      const bottomOfPage = visible + scrollY + 1 >= pageHeight
      return bottomOfPage || pageHeight < visible
    },
    /* For equipment list scan */
    getRespectiveEquipment () {
      if (this.search) {
        this.scannerFieldLoading = true
        hostAppApi.get(`${this.getHostRefApi}equipment/get_by_barcode/${this.search}`)
          .then((response) => {
            if (response && response.data && typeof response.data === 'number') {
              this.$router.push(`/equipments/${response.data}`)
            } else {
              this.$root.$emit('snackbar', { snackbar: true, color: 'info', text: 'message.mixed.noEquipmentFound' })
            }
          })
          .finally(() => { this.scannerFieldLoading = false })
      }
    }
  },
  watch: {
    search (val) {
      // this.debouncedSearch()
      if (!this.payload.showScanCode) this.debouncedSearch()
    },
    bottom (val) {
      if (val) {
        if (this.payload.list.length !== this.payload.totalCount) this.$eventBus.$emit('loadList', this.search)
      }
    }
  },
  beforeDestroy () {}
}
</script>
<style scoped>
  .custom-height {
    position: relative;
    width: 100%;
    max-height: 5.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 1px;
  }
</style>
